import React from 'react';

const title = "Gallery Photos";

const galleryList = [
    {
        imgUrl: 'assets/images/gallery/gallery7.jpg',
        imgAlt: 'gallery',
    },
    {
        imgUrl: 'assets/images/gallery/gallery8.jpg',
        imgAlt: 'gallery',
    },
    {
        imgUrl: 'assets/images/gallery/gallery9.jpg',
        imgAlt: 'gallery',
    },
    {
        imgUrl: 'assets/images/gallery/gallery10.jpg',
        imgAlt: 'gallery',
    },
    {
        imgUrl: 'assets/images/gallery/gallery11.jpg',
        imgAlt: 'gallery',
    },
    {
        imgUrl: 'assets/images/gallery/gallery12.jpg',
        imgAlt: 'gallery',
    },
    {
        imgUrl: 'assets/images/gallery/gallery7.jpg',
        imgAlt: 'gallery',
    },
    {
        imgUrl: 'assets/images/gallery/gallery8.jpg',
        imgAlt: 'gallery',
    },
    {
        imgUrl: 'assets/images/gallery/gallery9.jpg',
        imgAlt: 'gallery',
    },
]

const GalleryPost = () => {
    return (
        <div className="widget widget-gallery">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <div className="widget-body">
                <ul>
                    {galleryList.map((val, i) => (
                        <li key={i}>
                            <a href={`${val.imgUrl}`} className="img-pop">
                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default GalleryPost