import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import LetestProduct from '../component/sidebar/widget/letest-product';
import Pagination from '../component/sidebar/widget/pagination';
import PopularCategory from '../component/sidebar/widget/popular-category';
import Search from '../component/sidebar/widget/search';

const showingResult = "Showing 01 - 12 of 139 Results";


let ProductList = [
    {
        imgUrl: 'assets/images/shop/01.png',
        imgAlt: 'Product Thumb',
        title: 'Product Title Here',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/shop/02.png',
        imgAlt: 'Product Thumb',
        title: 'Product Title Here',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/shop/03.png',
        imgAlt: 'Product Thumb',
        title: 'Product Title Here',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/shop/04.png',
        imgAlt: 'Product Thumb',
        title: 'Product Title Here',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/shop/05.png',
        imgAlt: 'Product Thumb',
        title: 'Product Title Here',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/shop/06.png',
        imgAlt: 'Product Thumb',
        title: 'Product Title Here',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/shop/07.png',
        imgAlt: 'Product Thumb',
        title: 'Product Title Here',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/shop/08.png',
        imgAlt: 'Product Thumb',
        title: 'Product Title Here',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/shop/09.png',
        imgAlt: 'Product Thumb',
        title: 'Product Title Here',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
]



const ShopPage = () => {
    const [GridList, setGridList] = useState(true);

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Our Product Page'} curPage={'Shop'} />
            <div className="shop-page padding-top padding-bottom">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-lg-8 col-xl-9 mb-50">
                            <div className="shop-title d-flex flex-wrap justify-content-between">
                                <p>{showingResult}</p>
                                <div className={`product-view-mode ${GridList ? "gridActive" : "listActive"}`} >
                                    <a className="grid" onClick={() => setGridList(!GridList)}><i className="fas fa-th"></i></a>
                                    <a className="list" onClick={() => setGridList(!GridList)}><i className="fas fa-list"></i></a>
                                </div>
                            </div>
                            <div className={`shop-product-wrap row mb-30-none justify-content-center ${GridList ? "grid" : "list"}`} >
                                {ProductList.map((val, i) => (
                                    <div className="col-xl-4 col-md-6" key={i}>
                                        <div className="shop-item">
                                            <div className="product-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                <div className="product-action-link">
                                                    <a href="#"><i className="far fa-eye"></i></a>
                                                    <a href="#"><i className="far fa-heart"></i></a>
                                                    <a href="#"><i className="fas fa-cart-plus"></i></a>
                                                    <a href="#"><i className="fas fa-sync-alt"></i></a>
                                                </div>
                                            </div>
                                            <div className="product-content">
                                                <h5 className="title"><Link to="/shop-single">{val.title}</Link></h5>
                                                <p>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i> 
                                                    <span>(3 review)</span>
                                                </p>
                                                <h6 className="subtitle">{val.price}</h6>
                                            </div>
                                        </div>
                                        <div className="product-list-item">
                                            <div className="product-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                <div className="product-action-link">
                                                    <a href="#"><i className="far fa-eye"></i></a>
                                                    <a href="#"><i className="far fa-heart"></i></a>
                                                    <a href="#"><i className="fas fa-cart-plus"></i></a>
                                                    <a href="#"><i className="fas fa-sync-alt"></i></a>
                                                </div>
                                            </div>
                                            <div className="product-content">
                                                <h5 className="title"><Link to="/shop-single">{val.title}</Link></h5>
                                                <p>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i> 
                                                    <span>(3 review)</span>
                                                </p>
                                                <h6 className="subtitle">{val.price}</h6>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Pagination />
                        </div>
                        <div className="col-lg-4 col-xl-3 mb-50">
                            <div className="single-sidebar">
                                <Search />
                                <PopularCategory />
                                <LetestProduct />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default ShopPage;