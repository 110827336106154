import React from 'react';
import { Link } from 'react-router-dom';


const title = "latest products";


const productList = [
    {
        imgUrl: 'assets/images/shop/01.jpg',
        imgAlt: 'shop',
        title: 'Product Title Here',
        price: '$200.00',
    },
    {
        imgUrl: 'assets/images/shop/02.jpg',
        imgAlt: 'shop',
        title: 'Product Title Here',
        price: '$200.00',
    },
    {
        imgUrl: 'assets/images/shop/03.jpg',
        imgAlt: 'shop',
        title: 'Product Title Here',
        price: '$200.00',
    },
    {
        imgUrl: 'assets/images/shop/04.jpg',
        imgAlt: 'shop',
        title: 'Product Title Here',
        price: '$200.00',
    },
]

const LetestProduct = () => {
    return (
        <div className="widget widget-product">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <div className="widget-body">
                <ul>
                    {productList.map((val, i) => (
                        <li key={i}>
                            <div className="product-thumb">
                                <Link to="/shop-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                            </div>
                            <div className="product-content">
                                <h6><Link to="/shop-single">{val.title}</Link></h6>
                                <p>
                                    <i className="far fa-star"></i>
                                    <i className="far fa-star"></i>
                                    <i className="far fa-star"></i>
                                    <i className="far fa-star"></i>
                                    <i className="far fa-star"></i>
                                    <span>(3 review)</span>
                                </p>
                                <h6 className="subtitle">{val.price}</h6>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default LetestProduct;