import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';

const btnText = "Get Started Now";

const GalleryData = [
    {
        id: 1,
        cate: 'toddler',
        imgUrl: 'assets/images/gallery/g1.jpg',
        imgAlt: 'gallery',
    },
    {
        id: 2,
        cate: 'preschool',
        imgUrl: 'assets/images/gallery/g2.jpg',
        imgAlt: 'gallery',
    },
    {
        id: 3,
        cate: 'kinder',
        imgUrl: 'assets/images/gallery/g3.jpg',
        imgAlt: 'gallery',
    },
    {
        id: 4,
        cate: 'programe',
        imgUrl: 'assets/images/gallery/g4.jpg',
        imgAlt: 'gallery',
    },
    {
        id: 5,
        cate: 'toddler',
        imgUrl: 'assets/images/gallery/g5.jpg',
        imgAlt: 'gallery',
    },
    {
        id: 6,
        cate: 'kinder',
        imgUrl: 'assets/images/gallery/g6.jpg',
        imgAlt: 'gallery',
    },
    {
        id: 7,
        cate: 'preschool',
        imgUrl: 'assets/images/gallery/g7.jpg',
        imgAlt: 'gallery',
    },
    {
        id: 8,
        cate: 'programe',
        imgUrl: 'assets/images/gallery/g8.jpg',
        imgAlt: 'gallery',
    },
]

const GalleryTwo = () => {
    const [items, setItems] = useState(GalleryData);
    const filterItem = (categItem) => {
        const updateItems = GalleryData.filter((curElem) => {
            return curElem.cate === categItem;
        });
        setItems(updateItems);
    }

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Our School Photos'} curPage={'Gallery'} />
            <div className="gallery-section padding-top padding-bottom pos-rel">
                <div className="top-shape-center">
                    <img src="./assets/css/img/gallery1.png" alt="css" />
                </div>
                <div className="bottom-shape-left">
                    <img src="./assets/css/img/bottom-shape.png" alt="css" />
                </div>
                <div className="container">
                    <ul className="filter-2">
                        <li onClick={() => setItems(GalleryData) }><span className="category">Show All</span><span className="amount">08</span></li>
                        <li onClick={() => filterItem('toddler') }><span className="category">toddlers</span><span className="amount">02</span></li>
                        <li onClick={() => filterItem('preschool') }><span className="category">preschool</span><span className="amount">02</span></li>
                        <li onClick={() => filterItem('kinder') }><span className="category">pre-k program</span><span className="amount">02</span></li>
                        <li onClick={() => filterItem('programe') }><span className="category">kindergarten</span><span className="amount">02</span></li>
                    </ul>
                    <div className="masonary-wrapper three-items d-flex flex-wrap justify-content-center">
                        { items.map((elem) => {
                            const { id, imgUrl, imgAlt } = elem;
                            return (
                                <div className="masonary-item" key={id}>
                                    <div className="masonary-thumb">
                                        <a href={imgUrl} className="img-pop"><i className="fas fa-expand"></i></a>
                                        <img src={imgUrl} alt={imgAlt} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="load-more">
                        <Link to="/login" className="custom-button"><span>{btnText}</span></Link>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default GalleryTwo;