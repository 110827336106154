import React from 'react';

const title = "Post Category";

const categoryList = [
    {
        text: 'Template',
        count: '05',
        link: '#',
    },
    {
        text: 'Codecanyon',
        count: '06',
        link: '#',
    },
    {
        text: 'Graphicriver',
        count: '05',
        link: '#',
    },
    {
        text: 'Photodune',
        count: '04',
        link: '#',
    },
    {
        text: 'Videohive',
        count: '27',
        link: '#',
    },
    {
        text: '3docean',
        count: '13',
        link: '#',
    },
    {
        text: 'WordPress',
        count: '22',
        link: '#',
    },
    {
        text: 'Joomla',
        count: '17',
        link: '#',
    },
]

const PopularCategory = () => {
    return (
        <div className="widget widget-category">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <div className="widget-body">
                <ul>
                    {categoryList.map((val, i) => (
                        <li key={i}>
                            <a href={val.link}><span><i className="fas fa-angle-double-right"></i>{val.text}</span><span>{val.count}</span></a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default PopularCategory;