import React from 'react';

const title = "Popular Tags";

const tagsList = [
    {
        text: 'theme',
        link: '#',
    },
    {
        text: 'Template',
        link: '#',
    },
    {
        text: 'codecanyon',
        link: '#',
    },
    {
        text: 'videohive',
        link: '#',
    },
    {
        text: 'audiojungle',
        link: '#',
    },
    {
        text: '3docean',
        link: '#',
    },
    {
        text: 'theme',
        link: '#',
    },
    {
        text: 'Template',
        link: '#',
    },
    {
        text: 'codecanyon',
        link: '#',
    },
]

const PopularTag = () => {
    return (
        <div className="widget widget-tags">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <div className="widget-body">
                <ul className="tags">
                    {tagsList.map((val, i) => (
                        <li key={i}>
                            <a href={val.link}>{val.text}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default PopularTag