import React from 'react';

const cate = "About Our kittons";
const title = "A Friendly School Having Proud of Their Students";
const desc = "Dolor ame consectetur elite eusmod tempor dunt aliqua utas enim veniam tempore quis ipsum nostrud ipsume amet consectetur adpisicing elit sedo eiusmod tempo incdidunt labore dolore magna aliquat enim minim veniam nostrud abori nisut alquip exea commodo consequat duis aute irure aliqua enim minim veniam quis nostrud ullamco laboris nisiut aliquip";
const infoTitle = "Call to ask any question";
const phoneNumber = "+0123-4056-7890";


const nullaList = [
    {
        imgUrl: 'assets/images/about/icon01.png',
        imgAlt: 'about',
        text: 'Learning Environment',
    },
    {
        imgUrl: 'assets/images/about/icon02.png',
        imgAlt: 'about',
        text: 'Professional Teachers',
    },
    {
        imgUrl: 'assets/images/about/icon03.png',
        imgAlt: 'about',
        text: 'Programs For Everyone',
    },
    {
        imgUrl: 'assets/images/about/icon04.png',
        imgAlt: 'about',
        text: 'Professional Teaching',
    },
]

const About = () => {
    return (
        <section className="about-section section-right-shape padding-bottom padding-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about-video mr-lg-4">
                            <img src="assets/images/about/about01.jpg" alt="about" />
                            <a href="https://www.youtube.com/embed/6E9J8biF8RE" className="video-button popup"><i className="flaticon-play"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content">
                            <div className="section-header left-style">
                                <span className="cate mt-0">{cate}</span>
                                <h3 className="title">{title}</h3>
                                <p>{desc}</p>
                            </div>
                            <ul className="nulla-list">
                                {nullaList.map((val, i) => (
                                    <li key={i}>
                                        <div className="thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                        <div className="content">
                                            {val.text}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="about-contact">
                                <div className="call-item">
                                    <div className="thumb">
                                        <img src="assets/images/about/call.png" alt="about" />
                                    </div>
                                    <div className="content">
                                        <span className="info">{infoTitle}</span>
                                        <h5 className="subtitle"><a href="Tel:25451245452315">{phoneNumber}</a></h5>
                                    </div>
                                </div>
                                <div className="sign">
                                    <img src="assets/images/about/sign.png" alt="about" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About