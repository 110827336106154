import React from 'react';
import { Link } from 'react-router-dom';

const title = "About kitton";
const desc = "Assertively develop team driven niche marketis and revolutionary servicesre Synergistically parallel task ntrinsicly reintermediate best credibly.";
const blogTitle = "Recent Blog Post";
const signTitle = "Sign up for open day";
const signDesc = "Enter your email and get latest updates and offers subscribe us";



const contactList = [
    {
        iconName: 'fas fa-headphones-alt',
        text: '+08 645 280 947, 0289642',
    },
    {
        iconName: 'fas fa-home',
        text: '20 High Street, Blazfield Ave,250 USA',
    },
    {
        iconName: 'fas fa-globe-asia',
        text: 'www.yoursitename.org',
    },
]

const socialList = [
    {
        iconName: 'fab fa-facebook-f',
        className: 'facebook',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-twitter',
        className: 'twitter',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-linkedin-in',
        className: 'linkedin',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-instagram',
        className: 'instagram',
        siteLink: '#',
    },
    {
        iconName: 'fab fa-pinterest',
        className: 'pinterest',
        siteLink: '#',
    },
]

const recentBlogList = [
    {
        imgUrl: 'assets/images/footer/blog1.png',
        imgAlt: 'blog thumb',
        title: 'Remo Suppor Center What For Semiconductor Provider',
        date: 'April 08,2023',
    },
    {
        imgUrl: 'assets/images/footer/blog2.png',
        imgAlt: 'blog thumb',
        title: 'Remo Suppor Center What For Semiconductor Provider',
        date: 'April 08,2023',
    },
]


const Footer = () => {
    return (
        <footer className="bg_img" style={{backgroundImage: "url(/assets/images/footer/footer-bg.jpg)"}}>
            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4 mb--50">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-widget widget-about">
                                <h5 className="title">{title}</h5>
                                <p>{desc}</p>
                                <ul className="contact">
                                    {contactList.map((val, i) => (
                                        <li key={i}><i className={val.iconName}></i>{val.text}</li>
                                    ))}
                                </ul>
                                <ul className="social-icons">
                                    {socialList.map((val, i) => (
                                        <li key={i}>
                                            <a href={val.siteLink} className={val.className}><i className={val.iconName}></i></a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-widget widget-blog">
                                <h5 className="title">{blogTitle}</h5>
                                <ul className="footer-blog">
                                    {recentBlogList.map((val, i) => (
                                        <li key={i}>
                                            <div className="thumb">
                                                <Link to="/blog-single">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </Link>
                                            </div>
                                            <div className="content">
                                                <Link to="/blog-single">{val.title}</Link>
                                                <span>{val.date}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 pl-xl-4">
                            <div className="footer-widget widgt-form">
                                <h5 className="title">{signTitle}</h5>
                                <p>{signDesc}</p>
                                <form className="footer-form">
                                    <input type="text" placeholder="Enter your email" name="email" />
                                    <button type="submit">
                                        <span className="shape"></span>
                                        <span><i className="flaticon-vegan"></i> Subscribe Now!</span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <p> &copy; 2023 All Rights Reserved. Designed by <a href="https://www.templatemonster.com/authors/gullatheme">GullaTheme</a></p>
                </div>
            </div>
        </footer>
    )
}

export default Footer