import React from 'react';

const cate = "Photo Gallery";
const title = "Our All School Photos Gallery";

const galleryList = [
    {
        imgUrl: 'assets/images/gallery/gallery1.jpg',
        imgAlt: 'gallery',
        title: 'Infants Learnings 2017',
    },
    {
        imgUrl: 'assets/images/gallery/gallery2.jpg',
        imgAlt: 'gallery',
        title: 'Art And Design Event 2018',
    },
    {
        imgUrl: 'assets/images/gallery/gallery3.jpg',
        imgAlt: 'gallery',
        title: 'Painting Course 2019',
    },
    {
        imgUrl: 'assets/images/gallery/gallery4.jpg',
        imgAlt: 'gallery',
        title: 'Early Years Math 2020',
    },
    {
        imgUrl: 'assets/images/gallery/gallery5.jpg',
        imgAlt: 'gallery',
        title: 'Letters Matching 2021',
    },
    {
        imgUrl: 'assets/images/gallery/gallery6.jpg',
        imgAlt: 'gallery',
        title: 'Advance Learning 2023',
    },
]



const Gallery = () => {
    
    return (
        <section className="gallery-section padding-top padding-bottom pos-rel">
            <div className="top-shape-center">
                <img src="assets/css/img/gallery1.png" alt="css" />
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-header">
                            <span className="cate">{cate}</span>
                            <h3 className="title">{title}</h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mb--40--50">
                    {galleryList.map((val, i) => (
                        <div className="col-xl-4 col-md-6 col-sm-10" key={i}>
                            <div className="gallery-item">
                                <div className="gallery-inner">
                                    <div className="gallery-thumb">
                                        <a href={`${val.imgUrl}`} className="img-pop">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </a>
                                    </div>
                                    <div className="gallery-content">
                                        <h6 className="title">{val.title}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}



export default Gallery

