import React, { Fragment } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import Archive from '../component/sidebar/widget/archive';
import GalleryPost from '../component/sidebar/widget/gallery-post';
import Pagination from '../component/sidebar/widget/pagination';
import PopularCategory from '../component/sidebar/widget/popular-category';
import PopularPost from '../component/sidebar/widget/popular-post';
import PopularTag from '../component/sidebar/widget/popular-tag';
import Search from '../component/sidebar/widget/search';

const postItem = [
    {
        imgUrl: 'assets/images/blog/blog11.jpg',
        imgAlt: 'blog',
        title: 'Quickly integrate alternative methods of.',
        desc: 'Completely deploy focused supply chains rather than viraling an e-services productivate pandemc experiences via interoperable niche markets. Dramatically incentivize superior functionalities with Rapidiously evolve impactful core competencies without web-enabled vortals',
        metaList: [
            {
                text: 'William Khanna',
                iconName: 'far fa-user',
                link: '#',
            },
            {
                text: '124 Likes',
                iconName: 'far fa-heart',
                link: '#',
            },
            {
                text: '09 Comments',
                iconName: 'far fa-comments',
                link: '#',
            },
        ],
    }
]


const sliderItem = [
    {
        title: 'Why do aerobically have better Math Skills',
        desc: 'Completely deploy focused supply chains rather than viraling an e-services productivate pandemc experiences via interoperable niche markets. Dramatically incentivize superior functionalities with Rapidiously evolve impactful core competencies without web-enabled vortals',
        sliderList: [
            {
                imgUrl: 'assets/images/blog/blog12.jpg',
                imgAlt: 'blog',
            },
            {
                imgUrl: 'assets/images/blog/blog13.jpg',
                imgAlt: 'blog',
            },
            {
                imgUrl: 'assets/images/blog/blog11.jpg',
                imgAlt: 'blog',
            },
        ],
        metaList: [
            {
                text: 'William Khanna',
                iconName: 'far fa-user',
                link: '#',
            },
            {
                text: '124 Likes',
                iconName: 'far fa-heart',
                link: '#',
            },
            {
                text: '09 Comments',
                iconName: 'far fa-comments',
                link: '#',
            },
        ],
    }
]


const videoItem = [
    {
        imgUrl: 'assets/images/blog/blog12.jpg',
        imgAlt: 'blog',
        videoLink: 'https://www.youtube.com/embed/6E9J8biF8RE',
        title: 'Authoritively efective expertise whereas.',
        desc: 'Completely deploy focused supply chains rather than viraling an e-services productivate pandemc experiences via interoperable niche markets. Dramatically incentivize superior functionalities with Rapidiously evolve impactful core competencies without web-enabled vortals',
        metaList: [
            {
                text: 'William Khanna',
                iconName: 'far fa-user',
                link: '#',
            },
            {
                text: '124 Likes',
                iconName: 'far fa-heart',
                link: '#',
            },
            {
                text: '09 Comments',
                iconName: 'far fa-comments',
                link: '#',
            },
        ],
    }
]

const qouteItem = [
    {
        qoute: 'Rapdiou Qntegrat Distrbute Supply Chains Through Markey Position Bestng Chans Throug Marke Posito Bestin Practces Ieractve Fashon Sound Qources Fashion Economically And Sound Qources',
        title: 'Uniquely architect alternative through.',
        desc: 'Completely deploy focused supply chains rather than viraling an e-services productivate pandemc experiences via interoperable niche markets. Dramatically incentivize superior functionalities with Rapidiously evolve impactful core competencies without web-enabled vortals',
        metaList: [
            {
                text: 'William Khanna',
                iconName: 'far fa-user',
                link: '#',
            },
            {
                text: '124 Likes',
                iconName: 'far fa-heart',
                link: '#',
            },
            {
                text: '09 Comments',
                iconName: 'far fa-comments',
                link: '#',
            },
        ],
    }
]




const BlogClassic = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Recent Blog Posts'} curPage={'Blog'} />
            <div className="blog-section padding-top padding-bottom">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-lg-8 mb-50">
                            <article className="blog-article">
                                {postItem.map((val, i) => (
                                    <div className="post-item post-classic" key={i}>
                                        <div className="post-thumb">
                                            <Link to="/blog-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                        </div>
                                        <div className="post-content">
                                            <div className="post-top">
                                                <h3 className="title"><Link to="/blog-single">{val.title}</Link></h3>
                                                <ul className="meta-post">
                                                    {val.metaList.map((val, i) => (
                                                        <li key={i}>
                                                            <a href={val.link}><i className={val.iconName}></i> {val.text}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <p>{val.desc}</p>
                                                <Link to="/blog-single" className="custom-button"><span>read more</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {sliderItem.map((val, i) => (
                                    <div className="post-item post-classic" key={i}>
                                        <div className="pos-rel oh">
                                            <div className="post-slider">
                                                <Slider {...settings}>
                                                    {val.sliderList.map((val, i) => (
                                                        <div className="post-thumb d-block" key={i}>
                                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                        <div className="post-content">
                                            <div className="post-top">
                                                <h3 className="title"><Link to="/blog-single">{val.title}</Link></h3>
                                                <ul className="meta-post">
                                                    {val.metaList.map((val, i) => (
                                                        <li key={i}>
                                                            <a href={val.link}><i className={val.iconName}></i> {val.text}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <p>{val.desc}</p>
                                                <Link to="/blog-single" className="custom-button"><span>read more</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {videoItem.map((val, i) => (
                                    <div className="post-item post-classic" key={i}>
                                        <div className="video-area">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            <a href={val.videoLink} className="video-button popup"><i className="flaticon-play"></i></a>
                                        </div>
                                        <div className="post-content">
                                            <div className="post-top">
                                                <h3 className="title"><Link to="/blog-single">{val.title}</Link></h3>
                                                <ul className="meta-post">
                                                    {val.metaList.map((val, i) => (
                                                        <li key={i}>
                                                            <a href={val.link}><i className={val.iconName}></i> {val.text}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <p>{val.desc}</p>
                                                <Link to="/blog-single" className="custom-button"><span>read more</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                
                                {qouteItem.map((val, i) => (
                                    <div className="post-item post-classic" key={i}>
                                        <blockquote className="double-cost">
                                            <div className="quotes">
                                                {val.qoute}
                                            </div>
                                        </blockquote>
                                        <div className="post-content">
                                            <div className="post-top">
                                                <h3 className="title"><Link to="/blog-single">{val.title}</Link></h3>
                                                <ul className="meta-post">
                                                    {val.metaList.map((val, i) => (
                                                        <li key={i}>
                                                            <a href={val.link}><i className={val.iconName}></i> {val.text}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <p>{val.desc}</p>
                                                <Link to="/blog-single" className="custom-button"><span>read more</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </article>
                            <Pagination />
                        </div>
                        <div className="col-lg-4 mb-50">
                            <aside>
                                <Search />
                                <PopularCategory />
                                <PopularPost />
                                <Archive />
                                <GalleryPost />
                                <PopularTag />
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default BlogClassic;