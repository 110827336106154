import React, { Fragment, useState } from 'react';
import Slider from "react-slick";

import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/page-header';
import LetestProduct from '../component/sidebar/widget/letest-product';
import PopularCategory from '../component/sidebar/widget/popular-category';
import Rating from '../component/sidebar/widget/rating';
import Search from '../component/sidebar/widget/search';


const thumbList = [
    {
        imgUrl: 'assets/images/shop/10.png',
        imgAlt: 'product slider',
    },
    {
        imgUrl: 'assets/images/shop/11.png',
        imgAlt: 'product slider',
    },
    {
        imgUrl: 'assets/images/shop/12.png',
        imgAlt: 'product slider',
    },
    {
        imgUrl: 'assets/images/shop/13.png',
        imgAlt: 'product slider',
    },
]

let ReviewList = [
    {
        imgUrl: 'assets/images/shop/review-icon-four.png',
        imgAlt: 'Client thumb',
        name: 'Britney Doe',
        date: 'Posted on Jun 10, 2023 at 6:57 am',
        desc: 'Enthusiast build innovativ initiatives before lonterm high-impact awesome theme seo psd porta monetize covalent leadership after without resource.',
    },
    {
        imgUrl: 'assets/images/shop/review-icon-one.png',
        imgAlt: 'Client thumb',
        name: 'Morgana Cailot',
        date: 'Posted on Jun 10, 2023 at 6:57 am',
        desc: 'Enthusiast build innovativ initiatives before lonterm high-impact awesome theme seo psd porta monetize covalent leadership after without resource.',
    },
    {
        imgUrl: 'assets/images/shop/review-icon-two.png',
        imgAlt: 'Client thumb',
        name: 'Telford Bois',
        date: 'Posted on Jun 10, 2023 at 6:57 am',
        desc: 'Enthusiast build innovativ initiatives before lonterm high-impact awesome theme seo psd porta monetize covalent leadership after without resource.',
    },
    {
        imgUrl: 'assets/images/shop/review-icon-three.png',
        imgAlt: 'Client thumb',
        name: 'Cher Daviau',
        date: 'Posted on Jun 10, 2023 at 6:57 am',
        desc: 'Enthusiast build innovativ initiatives before lonterm high-impact awesome theme seo psd porta monetize covalent leadership after without resource.',
    },
]

const ShopSingle = () => {
    const [reviewShow, setReviewShow] = useState(true);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Our Product Page'} curPage={'Product Details'} />
            <div className="shop-page padding-top padding-bottom">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-lg-8 col-xl-9 mb-50">
                            <div className="product-details mb-30">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-12">
                                        <div className="product-single-thumb o">
                                            <Slider {...settings}>
                                                {thumbList.map((val, i) => (
                                                    <div className="thumb" key={i}>
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} className="m-auto" />
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="product-single-content">
                                            <h5 className="title">Product Title Here</h5>
                                            <p className="rating">
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <i className="far fa-star"></i>
                                                (3 review)
                                            </p>
                                            <h5 className="amount"> $ 340.00 </h5>
                                            <h5 className="subtitle">Product Description</h5>
                                            <p className="info"> Energistia an deliver atactica metrcs after avsionary Apropria trnsition enterpris an sources applications emerging psd template communities. </p>
                                            <form className="size-form">
                                                <div className="select-product">
                                                    <select className="select-bar">
                                                        <option>Select Size</option>
                                                        <option>SM</option>
                                                        <option>MD</option>
                                                        <option>LG</option>
                                                        <option>XL</option>
                                                        <option>XXL</option>
                                                    </select>
                                                </div>
                                                <div className="select-product">
                                                    <select className="select-bar">
                                                        <option>Select Color</option>
                                                        <option>Pink</option>
                                                        <option>Ash</option>
                                                        <option>Red</option>
                                                        <option>White</option>
                                                        <option>Blue</option>
                                                    </select>
                                                </div>
                                                <div className="cart-plus-minus">
                                                    <div className="dec qtybutton">-</div>
                                                    <input className="cart-plus-minus-box" type="text" name="qtybutton" defaultValue="1" />
                                                    <div className="inc qtybutton">+</div>
                                                </div>
                                                <div className="discount-code">
                                                    <input type="text" placeholder="Enter Discount Code" />
                                                </div>
                                                <button type="submit" className="custom-button"><span>Add To Cart</span></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="review">
                                <ul className={`review-nav ${reviewShow ? "RevActive" : "DescActive"}`} >
                                    <li onClick={() => setReviewShow(!reviewShow)} className="desc">Description</li>
                                    <li onClick={() => setReviewShow(!reviewShow)} className="rev">Reviews 4</li>
                                </ul>
                                <div className={`review-content ${reviewShow ? "review-content-show" : "description-show"}`} >
                                    <div className="review-showing">
                                        <ul className="content">
                                            {ReviewList.map((val, i) => (
                                                <li key={i}>
                                                    <div className="rev-thumb-2">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="rev-content-2">
                                                        <div className="entry-meta">
                                                            <div className="posted-on">
                                                                <a href="#">{val.name}</a>
                                                                <p>{val.date}</p>
                                                            </div>
                                                            <Rating />
                                                        </div>
                                                        <div className="entry-content">
                                                            <p>{val.desc}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        <div className="client-review">
                                            <div className="review-form">
                                                <div className="review-title">
                                                    <h5 className="title">Add a Review</h5>
                                                </div>
                                                <form action="#" className="row client-form">
                                                    <div className="col-md-4 col-12">
                                                        <input type="text" name="name" placeholder="Full Name" />
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <input type="text" name="email" placeholder="Email Adress" />
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="rating">
                                                            <span className="rating-title">Your Rating : </span>
                                                            <Rating />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-12 d-inline-flex">
                                                        <textarea rows="5" placeholder="Type Here Message"></textarea>
                                                    </div>
                                                    <div className="col-12 mb-0">
                                                        <button type="submit" className="custom-button"><span>Submit Review</span></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                        <div className="rev-item">
                                            <div className="rev-thumb">
                                                <img src="assets/images/shop/rev.jpg" alt="shop" />
                                            </div>
                                            <div className="rev-content">
                                                <ul>
                                                    <li>
                                                        Donec non est at libero vulputate rutrum.
                                                    </li>
                                                    <li>
                                                        Morbi ornare lectus quis justo gravida semper.
                                                    </li>
                                                    <li>
                                                        Pellentesque aliquet, sem eget laoreet ultrices.
                                                    </li>
                                                    <li>
                                                        Nulla tellus mi, vulputate adipiscing cursus eu, suscipit id nulla.
                                                    </li>
                                                    <li>
                                                        Donec a neque libero.
                                                    </li>
                                                    <li>
                                                        Pellentesque aliquet, sem eget laoreet ultrices.
                                                    </li>
                                                    <li>
                                                        Morbi ornare lectus quis justo gravida semper..
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-3 mb-50">
                            <div className="single-sidebar">
                                <Search />
                                <PopularCategory />
                                <LetestProduct />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default ShopSingle;